@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.w-100 {
  width: 100px;
}
.w-150 {
  width: 150px;
}

.w-180 {
  width: 180px;
}
.w-200 {
  width: 200px;
}

.w-f {
  width: 100%;
}

.sticky {
  position: sticky;
}
.top-0 {
  top: 0;
}
